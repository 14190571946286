<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbapks.com.pk/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" width="40" height="40" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbapks.com.pk/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbapks.com.pk/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a :href="downloadURL">Download</a>
          </li>
          <!-- @click="gotodownload()" -->
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="/blogs/">Blogs</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="/gb-whatsapp-old-version/">Old Version</a>
          </li>
          <li id="menu-item-33" class="language-selector menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <img src="@/assets/earth.svg" alt="Language Selector" @click="toggleLanguageMenu" />
            <!-- Language Menu -->
            <ul v-if="menuVisible" class="language-menu">
              <li v-for="(data, i) in lang" :key="`lang_${i}`">
                <a :href="data.lang === 'en' ? 'https://gbapks.com.pk/' : `https://gbapks.com.pk/${data.lang}/`" :class="{ active: selectedLanguage === data.lang }" :rel="selectedLanguage === data.lang ? 'canonical' : 'alternate'" :hreflang="data.lang">
                  {{ data.val }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #0078ff 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="150" height="150" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      GBWhatsApp
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.30</strong> | Size: <strong>77MB</strong>
                      </p>
                    </div>

                    <p v-if="$global.isMobileDevice()" class="has-text-align-center d">
                      Chatting and calling with extra custom features in GB WhatsApp is a new trend for WhatsApp users.
                    </p>
                    <p v-else class="has-text-align-center d">
                      Chatting and calling with extra custom features in GB WhatsApp is a new trend for WhatsApp users. GB WhatsApp is a modified version of WhatsApp which contains more features and privacy options. It allows users to hide online status, read deleted messages, unread messages and more.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <a class="wp-block-button__link wp-element-button" href="https://gbapks.com.pk/download-gbwhatsapp-apk/">Download GB WhatsApp
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <!-- Table of Contents -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li v-for="(item, index) in tocList" :key="index">
                        <a :href="'#TBC_' + index">
                          <span class="toc_number">{{ index + 1 }}.</span> {{ item }}
                        </a>
                      </li>
                    </ul>
                  </section>
                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0">
                    {{tocList[0]}}
                  </span>
                </h2>

                <p>
                  GB WhatsApp is a very popular MOD version of the official <a href="https://www.whatsapp.com/" class="jump-url" rel="nofollow" target="_blank">WhatsApp Messenger</a>, which comes with various advanced functions. Loved by millions worldwide, GB WhatsApp lets users enjoy features that are absent in the standard version of WhatsApp. The v18.30 Anti-Ban upgrade made <a href="/" class="jump-url">GB WhatsApp</a> more robust, secure, and full of features. It comes with advanced privacy control and the ability to customize chats, making it quite ideal for those seeking more than what is available on the official WhatsApp.
                </p>

                <h3>
                  App Info of <strong>GBWhatsapp APK</strong>
                </h3>
                <figure class="wp-block-table">
                  <table>
                    <tr>
                      <td>App</td>
                      <td>GBWhatsApp</td>
                    </tr>
                    <tr>
                      <td>Developer</td>
                      <td>Alex Mods</td>
                    </tr>
                    <tr>
                      <td>Version</td>
                      <td>18.30</td>
                    </tr>
                    <tr>
                      <td>Size</td>
                      <td>77MB</td>
                    </tr>
                    <tr>
                      <td>OS</td>
                      <td>Android 5.0+</td>
                    </tr>
                    <tr>
                      <td>Total Downloads</td>
                      <td>2+ Million</td>
                    </tr>
                    <tr>
                      <td>License</td>
                      <td>Free</td>
                    </tr>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_1">
                    {{tocList[1]}}
                  </span>
                </h2>
                <h3>Ghost Mode</h3>
                <p>Ghost Mode lets you turn invisible on the app. This means you can read messages without having the other person get a read receipt, showing them you've read their messages. It also hides the "typing" or "recording" notifications, making it totally private.</p>

                <h3>Hide Typing</h3>
                <p>GB WhatsApp supports hide typing features, which prevents other from knowing if you're typing messages. Therefore, you could type at your pace and respond in time without getting any pressure while preparing important or lengthy conversations.</p>

                <h3>Hide the Second Tick</h3>
                <p>Hides the second checkmark for the received messages; it would not highlight when the messages get delivered to the recipient. Pretty nice feature, helping you be in control of whomever you deal with.</p>

                <h3>Save Status</h3>
                <p>It's available to save and <a href="https://gbapks.com.pk/download-gbwhatsapp-status/" class="jump-url">download updates of the status of your contacts</a> directly to your phone. With this feature, one can easily save memorable photos or videos shared by friends. That's a very easy and efficient way to archive some moments shared online.</p>

                <h3>Popup Notifications</h3>
                <p>The feature of seeing new messages and calls right on your screen without opening the app is very helpful in case you are multitasking and just want to reply to messages in a few words. Moreover, this function will definitely not let you miss anything important, even if the app is minimized.</p>

                <h3>Emojis Effects</h3>
                <p>To add some fun and expressiveness to your conversations, it has more varieties of emojis with <a href="https://gbapks.com.pk/create-stickers-with-gbwhatsapp/" class="jump-url">special effects</a>, which make messages interactive and appealing. It will make sure your chats are noticed, and that emotions are well conveyed.</p>

                <h3>Lots of Themes</h3>
                <p>You will have thousands of downloadable themes for changing the outlook of the whole application. From minimalistic designs to bright and popping colors, there is literally something for everyone. Updates to the theme library are routine, so things never get stale.</p>

                <h3>Do Not Disturb (DND Mode)</h3>
                <p>Do Not Disturb mode will help you to block internet access for <a href="https://waplusapp.org/" class="jump-url">GB WhatsApp</a> while keeping your phone's connectivity intact. This feature is ideal for focusing on tasks or relaxing without interruptions from messages and calls. It ensures peace of mind by muting all app activity temporarily.</p>

                <h3>Text Message Broadcast Channel</h3>
                <p>It allows sending a message to more than one contact without creating a group. This is convenient for announcements, invitations, or updates, as it helps save time and effort. Unlike traditional group messages, recipients will not see one another's responses.</p>

                <h3>Advanced Media Sharing</h3>
                <p>This feature helps users to send high-quality photos and videos uncompressed. Besides, the advanced media sharing facility allows for sharing of file sizes as big as 700MB, and hence, important media becomes easy to share. This ensures your content remains pristine and looks really great.</p>

                <h3>Auto Reply</h3>
                <p>The Auto Reply feature allows you to set automated responses for incoming messages. This is particularly useful for managing conversations while you're busy or away. You can customize the replies to suit different situations, ensuring professional or friendly communication.</p>

                <h3>Unread Messages</h3>
                <p>The Unread Messages feature helps you keep tabs on messages that you haven't replied to. It keeps all the unread chats in one place for better prioritization and response to important conversations. This way, you will never miss an important message.</p>

                <h3>Schedule Message</h3>
                <p>Schedule Message allows you to schedule specific times for sending messages. This is really helpful in planning birthday wishes, reminders, or announcements beforehand. It will ensure timely communication without manual interference.</p>

                <h3>Filter Messages</h3>
                <p>Filter Messages allows you to arrange your conversations for easier searching of subjects or content in your chats. It helps you declutter your messages and find what you are looking for. This feature helps you keep organized and productive.</p>

                <h3>Amazing Fonts</h3>
                <p><a href="https://whatsplusapk.com/" class="jump-url">WhatsApp GB</a> provides a set of stylish and unique text styles. You can attach these fonts to messages and profiles for a touch of creativity. It enhances the beauty of chats, making them stand out.</p>

                <h3>Anti-Revoke Messages</h3>
                <p>GB WhatsApp helps you to view messages deleted by the sender, so you don't miss any important information or conversation. It is an excellent feature to keep the chats transparent.</p>

                <h3>Freeze Last Seen</h3>
                <p>Freeze Last Seen allows you to lock your last seen timestamp at a specific moment. This adds another layer of privacy by preventing others from knowing when you were last active. It's an excellent option for managing your online visibility.</p>

                <h3>Anti-Ban Feature</h3>
                <p>The Anti-Ban Feature saves your account from getting banned by WhatsApp through advanced technology. It ensures smooth usage of GB WhatsApp without any hindrance. Regular updates make this protection stronger and reliable.</p>

                <h2 class="h">
                  <span id="TBC_2">
                    {{tocList[2]}}
                  </span>
                </h2>
                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/Screenshot.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/Screenshot.webp" alt="gbwhatsapp" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_3"> {{tocList[3]}} </span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>WhatsApp</th>
                        <th>GB WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Customization Options</td>
                        <td>Limited</td>
                        <td>Extensive</td>
                      </tr>
                      <tr>
                        <td>Advanced Privacy Controls</td>
                        <td>Basic</td>
                        <td>Enhanced</td>
                      </tr>
                      <tr>
                        <td>Theme Selection</td>
                        <td>Not Available</td>
                        <td>Thousands of Themes</td>
                      </tr>
                      <tr>
                        <td>File Sharing Limit</td>
                        <td>Compressed</td>
                        <td>Original</td>
                      </tr>
                      <tr>
                        <td>Change Themes</td>
                        <td>Default</td>
                        <td>4000+</td>
                      </tr>
                      <tr>
                        <td>DDN Mode</td>
                        <td>×</td>
                        <td>√</td>
                      </tr>
                      <tr>
                        <td>Anti-Revoke Messages</td>
                        <td>×</td>
                        <td>√</td>
                      </tr>
                      <tr>
                        <td>Change Fonts</td>
                        <td>×</td>
                        <td>√</td>
                      </tr>
                      <tr>
                        <td>Hide Blue Ticks</td>
                        <td>×</td>
                        <td>√</td>
                      </tr>
                      <tr>
                        <td>Unread Messages</td>
                        <td>×</td>
                        <td>√</td>
                      </tr>
                      <tr>
                        <td>System Support</td>
                        <td>All Platforms</td>
                        <td>Android Only</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_4">
                    {{tocList[4]}}
                  </span>
                </h2>
                <h3>1. Backup Data:</h3>
                <ul>
                  <li>Open GB WhatsApp and go to Settings.</li>
                  <li>Navigate to Chats > Chat Backup.</li>
                  <li>Tap "Backup" to save your data locally.</li>
                </ul>
                <h3>2. Restore Data:</h3>
                <ul>
                  <li>The data may updated in the local folder after backup. </li>
                  <li>When you need to relogin GBWhatsApp, click restore button is ok. </li>
                </ul>

                <h2 class="h">
                  <span id="TBC_5"> {{tocList[5]}} </span>
                </h2>
                <h3>1. Backup Your WhatsApp Data:</h3>
                <ul>
                  <li>Open WhatsApp and go to Settings > Chats > Chat Backup.</li>
                  <li>Back up your chats to Google Drive and your local storage. </li>
                </ul>

                <h3>
                  2. Install GB WhatsApp:
                </h3>
                <ul>
                  <li>
                    Download and install the <a href="https://waplusapp.org/" class="jump-url">GB WhatsApp APK</a> from a trusted source.
                  </li>
                </ul>

                <h3>
                  3. Restore Data in GB WhatsApp:
                </h3>
                <ul>
                  <li>Change the folder name of WhatsApp into GBWhatsApp. Then login to the app and </li>
                  <li>All your chats and media on the local storage will be imported into GB WhatsApp.</li>
                </ul>

                <p>
                  <strong class="blue-bg">Also Read:</strong> <a href="https://gbapks.com.pk/switch-chats-whatsapp-gbwhatsapp/" class="jump-url">How to Switch Chats from WhatsApp to GBWhatsApp?</a>
                </p>

                <h2 class="h">
                  <span id="TBC_6"> {{tocList[6]}} </span>
                </h2>
                <h3>How to Install GB WhatsApp</h3>
                <h4>1. Enable Unknown Sources:</h4>
                <ul>
                  <li>Go to your phone's Settings > Security.</li>
                  <li>Toggle on "Unknown Sources" to allow third-party app installations.</li>
                </ul>
                <h4>2. Download GB WhatsApp:</h4>
                <ul>
                  <li>Click the button below to download the GB WhatsApp APK file.
                  </li>
                </ul>
                <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                  <div class="wp-block-button">
                    <a class="wp-block-button__link wp-element-button" href="https://gbapks.com.pk/download-gbwhatsapp-apk/">GB WhatsApp APK download</a>
                  </div>
                </div>
                <h4>3. Install the APK:</h4>
                <ul>
                  <li>Open the downloaded file and tap "Install."</li>
                  <li>Follow the on-screen instructions to complete the installation.</li>
                </ul>

                <h3>How to Get the GB WhatsApp Update</h3>
                <p>
                  GB WhatsApp developers frequently release new versions, typically every 2-3 months, to enhance anti-ban protection, improve user experience, and address potential issues. It's important to stay updated to enjoy the latest features and security enhancements.
                </p>
                <p>
                  If your existing version of GB WhatsApp is developed by AlexMods, it is crucial to download updates from the same developer. Switching to a different mod version, such as HeyMods or Android Wave, could result in compatibility issues or even data loss.
                </p>
                <p>
                  However, before uninstalling the <a href="https://gbapks.com.pk/gb-whatsapp-old-version/" class="jump-url">GBWhatsApp old version</a>, ensure you back up your chats. Once the backup is complete, proceed with the installation of the new version and restore your data seamlessly.
                </p>

                <h2 class="h">
                  <span id="TBC_7"> {{tocList[7]}} </span>
                </h2>
                <p>
                  You may receive a message "You are banned from using modified version of WhatsApp" after using GB WhatsApp. What should you do? Unfortunately, users have to switch to a new phone number to register a new account.
                </p>
                <p>
                  The developers have already received feedback from users across the world. They will keep updating the new version to test stability.
                </p>
                <p>
                  BTW: GB WhatsApp new version was relased!! <strong>Version : 18.30 - 2.24.16.76</strong>
                </p>
                <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                  <div class="wp-block-button">
                    <a class="wp-block-button__link wp-element-button" href="https://gbapks.com.pk/download-gbwhatsapp-apk/">Download GB WhatsApp</a>
                  </div>
                </div>

                <h2 class="h center">
                  <span id="TBC_8"> {{tocList[8]}} </span>
                </h2>
                <p>
                  If your account haven't been banned, you should decrease the usage of the unlocked features of GBWhatsApp. And, some regular users find a better way to use GBWhatsApp app. That is <a href="https://gbapks.com.pk/gbwhatsapp-link-companion-device/" class="jump-url">Companion Device</a>.
                </p>
                <ol>
                  <li>Install And Verify Official WhatsApp.</li>
                  <li>Open GBWhatsApp > Link as Companion Device.</li>
                  <li>Take Screenshot Of GBWhatsApp QR Code And Send It To Other Phone.</li>
                  <li>Open WhatsApp > Menu > Linked Devices > Link a Device.</li>
                  <li>Scan The QR Code On Official WhatsApp.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_9"> {{tocList[9]}} </span>
                </h2>
                <ol>
                  <li><strong><a href="https://gbwhatsapp.chat/" class="jump-url">GB WhatsApp</a></strong> - 40%</li>
                  <li><strong><a href="https://gbwhatapks.com/whatsapp-plus/" class="jump-url">FM WhatsApp</a></strong> - 25%</li>
                  <li><strong>YoWhatsApp</strong> - 15%</li>
                  <li><strong>OG WhatsApp</strong> - 10%</li>
                  <li><strong>WhatsApp Plus</strong> - 10%</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_10">
                    {{tocList[10]}}
                  </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is GBWhatsApp safe or legal?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      It's safe but voliated the policy and terms of WhatsApp. Though the app is a mod version of WhatsApp, it has large user base. People across the world are using it to enjoy unlimited features. You account might be banned, so, we recommend you to register a new phone number to use GBWhatsApp without sending important data or messages. 
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is GBWhatsApp free to use?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Yes, It's free. No hidden fee as well.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Can I use both WhatsApp and GB WhatsApp simultaneously one the same device?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Yes. But you should login via different phone numbers.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is GB WhatsApp over?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      No, GB WhatsApp is not over. However, users may encounter account bans or issues as it is a modified version, which violates WhatsApp's terms of service. Developers of the app frequently release updates with anti-ban features to address these issues.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Why do people choose GB WhatsApp?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      People choose this app for its customization and enhanced features that are not available in the official WhatsApp. 
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">How to fix login issue</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      If you're experiencing login issues with GB WhatsApp, ensure you are using the latest version of the app. Older versions may no longer work due to WhatsApp's security updates. 
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_11"> {{tocList[11]}} </span>
                </h2>
                <p>
                  <a href="https://gbpps.com.pk/" class="jump-url">GB WhatsApp APK</a> v18.30 enhanced anti ban protection in 2025. The feature-richapp is a good alternative to the official WhatsApp, offering enhanced privacy controls, customization options, and advanced functionalities like Ghost Mode, Anti-Ban, and Auto Reply. What's more, it's important to download WhatsApp GB from trusted sources and keep backup data regularly.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="snippet-box">
    <div class="snippet-title">
      Summary
    </div>

    <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
      <div class="aio-info">
        <div class="snippet-label-img snippet-clear">
          Author Rating
        </div>
        <div class="snippet-data-img">
          <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"></span>
        </div>
        <div class="snippet-clear" />
        <div class="aggregate_sec" itemprop="aggregateRating" itemscope="" itemtype="https://schema.org/AggregateRating">
          <div class="snippet-label-img">
            Aggregate Rating
          </div>
          <div class="snippet-data-img">
            <span itemprop="ratingValue">3.65</span> based on
            <span class="rating-count" itemprop="ratingCount">46258</span>
            votes
          </div>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Software Name
        </div>
        <div class="snippet-data-img">
          <span itemprop="name">GBWhatsApp APK</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Operating System
        </div>
        <div class="snippet-data-img">
          <span itemprop="operatingSystem">Android</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Software Category
        </div>
        <div class="snippet-data-img">
          <span itemprop="applicationCategory">App</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Price
        </div>
        <div class="snippet-data-img">
          <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
            <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Landing Page
        </div>
        <div class="snippet-data-img">
          <a itemprop="featureList" href="https://gbapks.com.pk/" class="jump-url">https://gbapks.com.pk/</a>
        </div>
        <div class="snippet-clear" />
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbapks.com.pk/privacy/">Privacy Policy</a>
          |
          <a href="https://gbapks.com.pk/about-us/">About Us</a>
          |
          <a href="https://gbapks.com.pk/contact-us/">Contact Us</a>

        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbapks.com.pk/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div style="height: 55px" aria-hidden="true" class="wp-block-spacer" v-if="$global.isMobileDevice()" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="downloadClick()">
    Download GBWhatsApp New
  </div>

  <ToTop />
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import ToTop from '@/components/ScrollToTop.vue';
export default {
  name: 'Home',
  components: {
    ToTop,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      menuVisible: false,
      selectedLanguage: 'en',
      lang: [{
          lang: 'en',
          val: 'English'
        },
        {
          lang: 'pt',
          val: 'Português'
        },
      ],
      tocList: [
        'What\'s GB Whatsapp APK 2025?',
        'Features of GB WhatsApp APK',
        'Screenshot',
        'Difference Between GBWhatsApp & WhatsApp',
        'How to Backup and Restore Data on GB WhatsApp',
        'How to Migrate from Official WhatsApp to GB WhatsApp?',
        'How to do GB WhatsApp Download on Android Phone ?',
        'You are banned from using modified version of WhatsApp',
        'How to link GB WhatsApp as a Linked Device',
        'Top 5 alternative WhatsApp Mods',
        'FAQ',
        'Conclusion'
      ]
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `https://gbapks.com.pk/download-gbwhatsapp-apk${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return 'https://gbapks.com.pk/download-gbwhatsapp-apk/'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `https://gbapks.com.pk/download-gbwhatsapp-apk${params}/`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();
    document.documentElement.lang = this.selectedLanguage;
    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    toggleLanguageMenu() {
      this.menuVisible = !this.menuVisible;
    },
    // changeLanguage(lang) {
    //   console.log(lang);
    //   // 处理语言选择变化
    //   // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
    //   this.$router.push({
    //     name: `${this.selectedLanguage}-gb`
    //   });
    // },
    downloadClick() {
      this.$router.push({
        name: 'download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbapks.com.pk',
          appName: 'GB_gbapks.com.pk',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/download-gbwhatsapp-apk" + params;
      // return `https://gbapks.com.pk/download-gbwhatsapp-apk${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbapks.com.pk&appName=GB_gbapks.com.pk`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
