import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue'
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp APK Download with Anti-Ban Enhanced 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GBWhatsApp released a new version with enhanced anti-ban protection. Download GB WhatsApp APK to try those appealing features in our site.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/'
				}
			]
		},
		component: Home,
	},
	{
		path: '/pt/',
		name: 'pt-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `WhatsApp GB APK Download com Proteção Anti-Ban Melhorada 2025`,
		keywords: 'gbwhatsapp, whatsapp gb, baixar gbwhatsapp, baixar whatsapp gb',
		description: `O GBWhatsApp lançou uma nova versão com proteção anti-ban aprimorada. Baixe o APK do WhatsApp GB para experimentar esses recursos atrativos em nosso site.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/pt/'
				}
			]
		},
		component: () => import('@/views/pt.vue'),
	},
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
		},
		component: Home,
	},
	{
		path: '/download-gbwhatsapp-apk',
		name: 'download',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp APK Download Page Updated`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Enter this page to download GB WhatsApp Latest Version for Android. We also list some alternative WhatsApp Mods.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/download-gbwhatsapp-apk/'
				}
			]
		},
		component: () => import('@/views/download.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: ` GBWhatsApp | Why do people prefer GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/blogs/'
				}
			]
		},
		component: () => import('@/views/blogs.vue'),
	},
	{
		path: '/create-stickers-with-gbwhatsapp',
		name: 'blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `Steps to Add Stickers in GBWhatsApp Easily`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Adding custom stickers in GB WhatsApp is a great way to make your chats more expressive and personalized. This blog covers methods and trouble shooting.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/create-stickers-with-gbwhatsapp/'
				}
			]
		},
		component: () => import('@/views/blog1.vue'),
	},
	{
		path: '/gbwhatsapp-link-companion-device',
		name: 'blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `Can You Still Use GBWhatsApp as a Companion Device?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Can you link GBWhatsApp to WhatsApp as a companion device successfully? Recently, a group pf users failed to use GB WhatsApp as a companion device.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/gbwhatsapp-link-companion-device/'
				}
			]
		},
		component: () => import('@/views/blog2.vue'),
	},
	{
		path: '/download-gbwhatsapp-status',
		name: 'blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Download GBWhatsApp Status for Android`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `In this blog, we introduced simple steps to download GBWhatsApp status. What's more, WhatsApp status can also be saved with other WhatsApp mods or other apps.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/download-gbwhatsapp-status/'
				}
			]
		},
		component: () => import('@/views/blog3.vue'),
	},
	{
		path: '/switch-chats-whatsapp-gbwhatsapp',
		name: 'blog4',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Switch Chats from WhatsApp to GBWhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you're searching for methods to switch WhatsApp to GBWhatsApp without losing chats, please read this blog. We also provied GB WhatsApp latest version APK.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/switch-chats-whatsapp-gbwhatsapp/'
				}
			]
		},
		component: () => import('@/views/blog4.vue'),
	},
	{
		path: '/gb-whatsapp-old-version',
		name: 'old-version',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp Old Versions (2023, 2024, 2025) Free Download`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `This site provides GB WhatsApp all versions download sorted by years. It's safe to download the GB WHatsApp APK old verison safely in this site.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/gb-whatsapp-old-version/'
				}
			]
		},
		component: () => import('@/views/old.vue'),
	},
	{
		path: '/privacy',
		name: 'privacy',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/privacy/'
				}
			]
		},
		component: () => import('@/views/privacy.vue'),
	},
	{
		path: '/about-us',
		name: 'about',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/about-us/'
				}
			]
		},
		component: () => import('@/views/about.vue'),
	},
	{
		path: '/contact-us',
		name: 'contact',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/contact-us/'
				}
			]
		},
		component: () => import('@/views/contact.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.pk/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
