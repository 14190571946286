<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="https://gbapks.com.pk/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="https://gbapks.com.pk/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="https://gbapks.com.pk/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a @click="gotodownload()">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbapks.com.pk/blogs/">Blogs</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbapks.com.pk/gb-whatsapp-old-version/">Old Version</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <div class="blog-list-row">
              <div v-for="(data, i) in bloglist" :key="i" class="blog-list-item"
                @click="goto(data.link)">
                <div class="blog-list-img">
                  <img :src="data.poster" :alt="data.alt">
                </div>
                <div class="blog-list-content">
                  <div class="blog-list-title">
                    {{ data.title }}
                  </div>
                  <div class="blog-list-descript">
                    {{ data.desc }}
                  </div>
                  <div class="blog-list-date">
                    {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://gbapks.com.pk/privacy/">Privacy Policy</a>
            |
          <a href="https://gbapks.com.pk/about-us/">About Us</a>
          |
          <a href="https://gbapks.com.pk/contact-us/">Contact Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbapks.com.pk/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>
  <div style="height: 55px" aria-hidden="true" class="wp-block-spacer" v-if="$global.isMobileDevice()" />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/blogspc.scss';

export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      bloglist: [
        {
          title: 'How to Transfer Chats from WhatsApp to GB WhatsApp in Easy Steps',
          poster: require('@/assets/blog-cv-4.webp'),
          desc: "GB WhatsApp became so popular because of some unique features that were provided, which had overcome the limitations of official WhatsApp. The transferring of chat history is one of the important steps for those who plan to switch to this modified version. This guide will explain why people use GB WhatsApp, how to transfer WhatsApp chats to GB WhatsApp, and how to revert if needed.",
          link: '/switch-chats-whatsapp-gbwhatsapp',
          alt: 'switch chats whatsapp gbwhatsapp',
        },
        {
          title: 'How to Download GBWhatsApp Status in Easy Steps',
          poster: require('@/assets/blog-cv-3.webp'),
          desc: "GBWhatsApp is a very popular modded version of WhatsApp that allows its users to customize their WhatsApp with some added features. Among several other features, GBWhatsApp can download WhatsApp status shared by your contacts, which cannot be done in the actual WhatsApp.",
          link: '/download-gbwhatsapp-status',
          alt: 'download gbwhatsapp status',
        },
        {
          title: 'Can You Link GBWhatsApp as a Companion Device?',
          poster: require('@/assets/blog-cv-2.webp'),
          desc: "GBWhatsApp has gained popularity for its customization features and unique functionalities that go beyond the capabilities of the official WhatsApp. However, some users have recently faced challenges while attempting to use GBWhatsApp as a companion device, including the \"couldn\'t link device\" error. In this article, we\'ll explore",
          link: '/gbwhatsapp-link-companion-device',
          alt: 'gbwhatsapp companion device',
        },
        {
          title: 'How to Add Stickers in GB WhatsApp?',
          poster: require('@/assets/blog-cv-1.webp'),
          desc: "Adding stickers to your chats can make conversations more engaging, creative, and unique to you. Stickers are a fun way to convey emotions, humor, and even messages that might not be as expressive through text alone. With GB WhatsApp, you have the flexibility to personalize these stickers, so you can truly express yourself.",
          link: '/create-stickers-with-gbwhatsapp',
          alt: 'create sticker gbwhatsapp',
        },
      ],
    };
  },
  mounted () { 
    document.documentElement.lang = 'en';

  },
  methods: {
    goto (url) {
      window.location.href = url;
    },
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = 'https://gbapks.com.pk/download-gbwhatsapp-apk' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
