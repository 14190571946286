<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbapks.com.pk/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbapks.com.pk/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbapks.com.pk/" aria-current="page">Home</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a @click="gotodownload()">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbapks.com.pk/blogs/">Blogs</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbapks.com.pk/gb-whatsapp-old-version/">Old Version</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <section id="section-1" class="section-content">
            <h1>
              Download GB WhatsApp APK Old Versions
            </h1>
            <p>
              Some users may prefer older versions of GB WhatsApp to minimize the risk of being banned by WhatsApp. To assist you, we've collected a comprehensive list of all GB WhatsApp versions, organized by release year and accompanied by main changelogs.
            </p>
            <p>
              Each APK listed here is safe to download, ensuring a reliable experience while exploring the features of previous versions.
            </p>
            <figure class="wp-block-table">
              <table>
                <tr>
                  <th>App</th>
                  <th>GB WhatsApp</th>
                </tr>
                <tr>
                  <td>Size</td>
                  <td>75.8M</td>
                </tr>
                <tr>
                  <td>Version</td>
                  <td>18.30</td>
                </tr>
                <tr>
                  <td>Update</td>
                  <td>2 days ago</td>
                </tr>
                <tr>
                  <td>Site</td>
                  <td><a href="https://gbapks.com.pk/" class="jump-url">https://gbapks.com.pk/ </a></td>
                </tr>
              </table>
            </figure>

            <div class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons big-download-btn">
              <div class="wp-block-button">
                <a id="dl" class="wp-block-button__link wp-element-button" target="_blank" rel="dl noopener" @click="download()">GB WhatsApp Latest Version</a>
              </div>
            </div>
            <div style="height: 25px" aria-hidden="true" class="wp-block-spacer" v-if="$global.isMobileDevice()" />

            <h2>
              GB WhatsApp Old Versions
            </h2>
            <div class="old-version-column">
              <div v-for="(item, index) in versions" :key="index" class="version-item">
                <div class="old-version-row">
                  <img src="../assets/shiny-logo.webp" class="head-img" alt="logo" />
                  <div class="version-detail">
                    <h4>GB WhatsApp v{{ item.version }} <span style="color:red;" v-if="item.isNew">(New)</span></h4>
                    <p><strong>Size:</strong> {{ item.size }}</p>
                    <p><strong>Released:</strong> {{ item.published }}</p>
                    <p v-if="item.notes">{{ item.notes }}</p>

                  </div>
                </div>
                <div class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons big-download-btn">
                  <div class="wp-block-button">
                    <a id="dl" class="wp-block-button__link wp-element-button" target="_blank" rel="dl noopener" @click="gotodownload()">Download Now</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbapks.com.pk/privacy/">Privacy Policy</a>
          |
          <a href="https://gbapks.com.pk/about-us/">About Us</a>
          |
          <a href="https://gbapks.com.pk/contact-us/">Contact Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbapks.com.pk/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>

  <div style="height: 55px" aria-hidden="true" class="wp-block-spacer" v-if="$global.isMobileDevice()" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="gotodownload()">
    Download GBWhatsapp APK
  </div>

  <ToTop />
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import ToTop from '@/components/ScrollToTop.vue';

export default {
  name: 'Home',
  components: {
    ToTop,
  },
  data() {
    return {
      pageName: 'home',
      versions: [{
          version: "18.30-2.24.16.76",
          size: "77 MB",
          published: "November 2024",
          notes: "Guide to use link as a companion device"
        },
        {
          version: "18.10",
          size: "77 MB",
          published: "September 2024",
          notes: "Improved Anti-Ban Protection"
        },
        {
          version: "18.00",
          size: "75 MB",
          published: "August 2024",
          notes: "Improved Anti-Ban Protection & Many Fixes"
        },
        {
          version: "17.85",
          size: "70 MB",
          published: "May 2024",
          notes: "Add go to the first message"
        },
        {
          version: "17.80",
          size: "75 MB",
          published: "May 2024",
          notes: "Ghost Mode"
        },
        {
          version: "17.76",
          size: "75 MB",
          published: "April 2024",
          notes: "Extending the Expire Date"
        },
        {
          version: "17.70",
          size: "75 MB",
          published: "March 2024",
          notes: "Fix bugs"
        },
        {
          version: "17.60",
          size: "75 MB",
          published: "December 2023",
          notes: "Added 25+ New Feature"
        },
        {
          version: "17.55",
          size: "75 MB",
          published: "October 2023",
          notes: "Fix bugs and crash"
        },
        {
          version: "17.51",
          size: "75 MB",
          published: "July 2023",
          notes: "Fix bugs and crash"
        },
        {
          version: "17.45",
          size: "75 MB",
          published: "June 2023",
          notes: "Fix bugs and crash"
        },
        {
          version: "17.40",
          size: "75 MB",
          published: "May 2023",
          notes: "Fix bugs and crash"
        }
      ],
      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
    document.documentElement.lang = 'en';
  },
  methods: {
    gotodownload() {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = 'https://gbapks.com.pk/download-gbwhatsapp-apk' + params;
    },
    jump(url) {
      window.location.href = url;
    },

    downloadfrom(reason) {
      this.download();
      this.$logEvent(`download_from_${reason}`, 'download');
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    download() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
  margin-bottom: 30px;
}

.old-version-column {
  margin-bottom: 40px;

  .version-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 35px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-top: 20px;

    .big-download-btn {
      // margin: rem(20) 0 0 0;
      height: auto;

      .wp-block-button__link {
        margin-bottom: 0;
      }
    }

    .old-version-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: rem(220);
        width: 130px;
        height: 130px;
        margin-right: 24px;
      }

      .plus-img {
        flex: 1;
        height: rem(260);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: none;
        }
      }

      h4 {
        font-size: 26px;
        margin: 4px 0;
      }

      p {
        margin: 0;
        line-height: 26px;
      }
    }
  }
}
.wp-block-table {
  width: 70%;
  margin: 0 auto;
  th {
    text-align: start;
  }
}
@media (max-width:768px) {
  .old-version-column {
    .version-item {
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .old-version-row {
        padding: 6px 4px;
        flex-direction: column;
        text-align: center;

        img {
          margin-right: 0;
          width: 130px;
          height: 130px;
        }

        .version-detail {
          margin-left: 0;
          margin-top: 10px;

          .apk-detail-wrapper {
            .apk-detail {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .wp-block-table {
  width: 95%;
}
}

</style>
